import React from "react"
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../components/common/header";
import Footer from "../components/common/footer";
import CardGrid from "../components/grid/card-grid";

import "./../styles/style.scss";

class ProjectsPage extends React.Component {

    render() {
        const { data } = this.props;
        const posts = data.allMarkdownRemark.edges;

        return (
            <div>
                <Header pageName={"Projects"}/>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={"section-title"}>
                                <h2>Projects</h2>
                                {/* <p></p> */}
                            </div>
                        </Col>
                    </Row>

                    { posts.length > 0 && (
                        <CardGrid posts={posts} postsPerPage={12} />
                    )}
                </Container>
                <Footer />
            </div>
        )
    }
}

export default ProjectsPage;


export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allMarkdownRemark(filter: {frontmatter: {tags: {in: "Project"}}}, sort: {fields: [frontmatter___date], order: DESC }) {
        edges {
            node {
                id
                excerpt(pruneLength: 100)
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    tags
                    author
                    description
                    featuredImage {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
}
`
